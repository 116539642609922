import React from "react"
import styled from "styled-components"

const Link = styled.a`
  border-bottom: 1px solid black;
`

const AmazingLink = ({ site, href, desc }) => (
  <li>
    <Link href={href} target="blank" rel="noreferrer noopener">
      {site}
    </Link>
    : {desc}
  </li>
)

export default AmazingLink
