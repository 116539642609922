import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from "../components/layout";
import TextWrapper from "../components/styles/TextWrapper";
import AmazingLink from "../components/styles/AmazingLink";
import SEO from "../components/seo";
export const _frontmatter = {
  "title": "Amazing",
  "path": "/amazing",
  "date": "05-07-2020"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <SEO title="Amazing" mdxType="SEO" />
  <TextWrapper mdxType="TextWrapper">
    <h2>Amazing Links</h2>
    <p>
      The internet is amazing, I added here a few worth noticing sites that
      deserved to be rememebred
    </p>
    <ul>
      <AmazingLink site="Pixel thoughts" href="https://www.pixelthoughts.co/#" desc="An incredible 60 second meditation page that is truly beautiful" mdxType="AmazingLink" />
      <AmazingLink site="Excalidraw" href="https://excalidraw.com/" desc="By far the best drawing tool on the web" mdxType="AmazingLink" />
      <AmazingLink site="Web desktops" href="https://simone.computer/#/webdesktops" desc="Coletion of sites that looks like desktops GUI" mdxType="AmazingLink" />
      <AmazingLink site="Xkcd" href="https://xkcd.com/" desc="A Classic!! There is so much good stuff here" mdxType="AmazingLink" />
      <AmazingLink site="Wait but Why" href="https://waitbutwhy.com/" desc="Mind blowing readings" mdxType="AmazingLink" />
      <AmazingLink site="ASCII flow" href="http://asciiflow.com/" desc="A visual editor for ASCII" mdxType="AmazingLink" />
    </ul>
  </TextWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      